@import '../../global/styles/lib';

html,
body {
	height: 100%;
	position: relative;
}

#theme-footer {
	bottom: 0;
	width: 100%;
	height: auto;
	background: $primary-color url(../../global/images/kohls-bg.jpg);
	background-attachment: fixed;
	color: $white;
	clear: both;
	@include gutter(top, padding);
	@include gutter(bottom, padding, 2);

	a:not(.button) {
		color: inherit;
	}

	.social-icons {
		.icon {
			display: inline-block;
			width: 2rem;

			&:not(:last-of-type) {
				margin-right: 1rem;
			}

			> a {
				opacity: .7;
				transition: opacity $hover-transition;

				&:hover { opacity: 1; }
				> svg {
					color: inherit;
					fill: currentColor;
				}
			} // > a
		} // .icon
	} // .social-icons

	.copyright {
		font-size: rem-calc(11);
	}

	.footer-tagline {
		@include show-for(medium);
		font-size: 2rem;
		font-weight: bold;
	}

	.menu {
		@include inline-list;
	}
	.menu-item {
		text-transform: uppercase;

		> a {
			display: inline-block;
			color: inherit;
			padding: 0;
		}
	} // .menu-item

	.site-logo {
		max-width: 6rem;

		> svg {
			fill: currentColor;
		}
	}

	.footer-left {
		order: 2;
	}
	.footer-right {
		order: 1;
		text-align: center;
	}

	.cd-credit {
		$text-offset: 100%;
		$transition-on: opacity .8s ease-in-out;
		$transition-off: opacity 3s ease-out;

		display: block;
		position: relative;
		width: 4rem;
		opacity: .4;
		transition: $transition-off;
		padding: 1rem;
		margin: 9rem auto 0;

		&:before,
		&:after {
			display: block;
			position: absolute;
			top: 50%;
			opacity: 0;
			pointer-events: none;
			transform: translateY(-28%);
			transition: $transition-off;
			font-size: .75rem;
			font-weight: 900;
			letter-spacing: rem-calc(1);
			line-height: 1;
			white-space: nowrap;
		}
		&:before {
			right: $text-offset;
			content: 'POWERED BY';
		}
		&:after {
			left: $text-offset;
			content: 'CHATMAN DESIGN';
		}

		> svg {
			fill: currentColor;
		}

		&:hover {
			opacity: 1;
			transition: $transition-on;

			&:before,
			&:after {
				opacity: 1;
				pointer-events: auto;
				transition: $transition-on;
			}
		}
	} // .cd-credit

	@include breakpoint(small only) {
		text-align: center;

		.menu-item {
			display: block;
			text-align: center;
			padding: .5rem 0;
			border-bottom: rem-calc(1) solid rgba(#fff,.1);

			&:not(:last-child):after {
				display: none;
			}
		} // .menu-item
	} // small
	@include breakpoint(medium down) {
		h1, .h1 {
			font-size: 2rem;
		}
	}
	@include breakpoint(medium) {
		.footer-left {
			order: 1;
		}
		.footer-right {
			order: 2;
			text-align: right;
			@include gutter(top, padding, 2);
		}

		.site-logo {
			position: relative;
			left: -1.5rem;
		}
	} // medium

} // #theme-footer
